/* #root {
  display: flex;
  flex-direction: row;
  justify-content: center;
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-modal {
  width: 600px;
}
.my-modal .modal-content {
  width: calc(600px + 2rem);
}
.sensor-graphic-modal {
  width: 80% !important;
  max-width: 80% !important;
  height: 80%;
}
.sensor-graphic-modal + .modal-dialog {
  width: 100%;
}
.sensor-graphic-modal .modal-content {
  width: 100%;
}
html,
body,
#root {
  height: 100%;
}
.pro-sidebar {
  top: 0;
  left: 0;
}
.ck-editor__editable {
  min-height: 500px;
}
.react-datepicker-wrapper {
  width: auto !important;
}
